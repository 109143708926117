.brand-mo-body-v1-wrapper {
    margin-top: 56px;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-top-banner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    background-color: var(--primaryp800);
    cursor: pointer;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-top-banner-container .brand-mo-body-v1-top-banner-text {
    color: var(--basewhite);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-0-container {
    position: relative;
    padding-top: 149.3%;
    background-image: url('https://img.positivehotel.io/2024/12/24/brand/mo/section-0/mo-section-0-bg.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-0-container .brand-mo-body-v1-section-0-logo {
    position: absolute;
    top: 22.14%;
    left: calc(50% - 109px);
    fill: var(--basewhite);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-0-container .brand-mo-body-v1-section-0-description{
    position: absolute;
    top: 32.857%;
    color: var(--basewhite);
    text-align: center;
    left: calc(50% - 109px);
    left: calc(50% - 162.8px);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-0-container .brand-mo-body-v1-section-0-detail-btn {
    position: absolute;
    top:72.857%;
    left: calc(50% - 22%);
    width: 44%;
    min-width: 125px;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-1-container { 
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 80px 0 80px 0;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-1-container .brand-mo-body-v1-section-1-title {
    text-align: center;
}


.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container {
    position: relative;
    padding-top: 408.8%;
    background-color:var(--baseblack);
    background-image: url('../../../../../../assets/images/temp/mo-section-2-bg.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-coach-container .brand-mo-body-v1-section-2-coach-title {
    position: absolute;
    top: 5.21%;
    left: calc(50% - 167.5px);
    color: var(--basewhite);   
    text-align: center;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-coach-container .brand-mo-body-v1-section-2-coach-square-img-card-mo-container {
    position: absolute;
    top: 12.263%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;

}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-experience-container {
    display: flex;
    gap: 6px;
    flex-direction: column;
    position: absolute;
    top: 39.399%;
    left: calc(50% - 25.33%);
    width: 50.66%;
    min-width: 150px;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-experience-container .brand-mo-body-v1-section-2-experience-text {
    color: var(--primaryp500);
    text-align: center;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-text-card-container {
    display: flex;
    gap: 24px;
    position: absolute;
    top: 48.07%;
    width: 100%;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-wellness-ticket-intro-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 57.33%;
    left: calc(50% - 167.5px);
}
.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-wellness-ticket-intro-container .brand-mo-body-v1-section-2-wellness-ticket-intro-title {
    color: var(--basewhite);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-wellness-ticket-intro-container .brand-mo-body-v1-section-2-wellness-ticket-intro-description {
    color: var(--basewhite);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-wellenss-ticket-purchase-container {
    display: flex;
    gap: 6px;
    flex-direction: column;
    position: absolute;
    top: 66.862%;
    left: calc(50% - 25.33%);
    width: 50.66%;
    text-align: center;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-wellenss-ticket-purchase-container .brand-mo-body-v1-section-2-wellenss-ticket-purchase-text {
    color: var(--primaryp500);
}
.brand-mo-body-v1-wrapper .brand-mo-body-v1-container .brand-mo-body-v1-section-2-container .brand-mo-body-v1-section-2-center-card-container {
    position: absolute;
    top: 74.233%;
    left: 0;
    height: 315px;
    width: 100%;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-section-3-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-top: 80px;
    
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-section-3-container .brand-mo-body-v1-section-3-content-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-section-3-container .brand-mo-body-v1-section-3-detail-btn {
    width: 50.66%;
    background-color: var(--baseblack);
    color: var(--basewhite);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-section-3-container .brand-mo-body-v1-section-3-detail-btn .detail-btn-mo-icon{
    fill: var(--basewhite);
}

.brand-mo-body-v1-wrapper .brand-mo-body-v1-section-3-container .brand-mo-body-v1-section-3-reservation-intro-img {
    padding-top: 169.2%;
    margin: 0 20.5px 79px 20.5px;
    min-width: 100%;
    background-image: url('https://img.positivehotel.io/2024/12/24/brand/mo/section-3/mo-section-3-intro-img.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
}