figure {
    display: flex;
    margin: 0;
    padding: 0;
    display: flex;
}
figure a{
    display: flex;
}
figure a {
    display: flex;
}
figure img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}