.detail-btn-pc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: var(--basewhite);
    width: 402px;
    height: 65px;
    border-radius: 70px;
    cursor: pointer;
}

.detail-btn-pc-container .detail-btn-pc-icon {
    fill: var(--baseblack);
}

.detail-btn-pc-container:hover{
    color: var(--basewhite);
    background-color: #d4d4d4;
}

.detail-btn-pc-container:hover .detail-btn-pc-icon {
    fill: var(--basewhite);
}