.custom-modal-enter {
transform: translateY(105%);
}
.custom-modal-enter-active {
transform: translateY(0%);
transition: transform 400ms cubic-bezier(0.1, 0.2, 0.37, 1);
}
.custom-modal-appear{
transform: translateY(105%);    
}
.custom-modal-appear-active {
transform: translateY(0%);
transition: transform 400ms cubic-bezier(0.1, 0.2, 0.37, 1);
}
.custom-modal-leave{
transform: translateY(0%);
}
.custom-modal-leave-active{
transform: translateY(105%);
transition: transform 300ms cubic-bezier(0.1, 0.2, 0.37, 1);
}