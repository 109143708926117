.brand-card-wrapper {
    width: 400px;
    height: 533px;
}

.brand-card-wrapper .brand-card-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 133.25%;
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    border-radius: var(--radius16);
    cursor: pointer;
}

.brand-card-wrapper .brand-card-container .brand-card-title {
    position: absolute;
    top: 32px;
    left: 40px;
}

.brand-card-wrapper .brand-card-container .brand-card-title .brand-card-title-text {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    white-space: pre;
    color: var(--basewhite);
}

.brand-card-wrapper .brand-card-container .brand-card-icon {
    position: absolute;
    top: 32px;
    right: 32px;
    fill: var(--basewhite);
    border-radius: 50%;
}

.brand-card-modal {
    position: relative;
}

.brand-card-modal .ant-modal-content {
    padding: 0;
    margin: 0;
    border-radius: var(--radius16);
}

.brand-card-modal .ant-modal-content .ant-modal-body {
    padding: 0;
    margin: 0;
    height: 820px;
    border-radius: var(--radius16);
}

.brand-card-modal .ant-modal-content .ant-modal-body .brand-card-modal-close-icon {
    position: absolute;
    top: 44px;
    right: 54px;
    stroke:var(--basewhite);
    cursor: pointer;
}

.brand-card-modal .ant-modal-content .ant-modal-body .brand-card-modal-img {
    border-radius: var(--radius16);
    object-fit: cover; 
    object-position: center;
 }

 .brand-card-modal .ant-modal-content .ant-modal-body .brand-card-modal-description {
    position: absolute;
    bottom: 40px;
    left: 50px;
    white-space: pre;
    font-size: 42px;
    font-weight: 600;
    line-height: 58.8px;
    text-align: left;
    color: var(--basewhite);
 }

 .brand-card-modal .ant-modal-content .ant-modal-body .brand-card-modal-description.isShow {
    position: absolute;
    bottom: 79px;
    left: 50px;
    white-space: pre;
    font-size: 42px;
    font-weight: 600;
    line-height: 58.8px;
    text-align: left;
    color: var(--basewhite);
 }