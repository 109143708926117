.brand-text-card-wrapper {
    width: 402px;
}

.brand-text-card-wrapper .brand-text-card-container {
    text-align: center;
}

.brand-text-card-wrapper .brand-text-card-container .brand-text-card-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.brand-text-card-wrapper .brand-text-card-container .brand-text-card-content-container .brand-text-card-main-content {
    font-size: 28px;
    font-weight: 500;
    line-height: 37px;
    color: var(--basewhite);
    white-space: pre;
}

.brand-text-card-wrapper .brand-text-card-container .brand-text-card-content-container .brand-text-card-sub-content {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.48px;    
    color: #FFFFFF80;
}