.brand-pc-body-wrapper {
    margin: 106px auto 0 auto;
    max-width: 1920px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-0 .brand-pc-body-section-0-bg-container {
    position: relative;
    padding-top: 46.875%;
    background-image: url('https://img.positivehotel.io/2024/12/24/brand/pc/section-0/pc-section-0-bg.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-0 .brand-pc-body-section-0-bg-container .brand-pc-body-section-0-logo {
    position: absolute;
    top:18.51%;
    z-index: 0;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-0 .brand-pc-body-section-0-bg-container .brand-pc-body-section-0-description {
    position: absolute;
    top: 43.6%;
    font-size: 24px;
    font-weight: 400;
    line-height: 37px;
    text-align: center;
    color: var(--basewhite);
    z-index: 0;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-0 .brand-pc-body-section-0-bg-container .brand-pc-body-section-0-detail-btn {
  position: absolute;
  top: 75.2%;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 100px;
    text-align: center;
    padding: 180px 340px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-1 .brand-pc-body-section-1-title {
    color: var(--secondarys850);
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    width: 543px;
    height: 116px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-1 .brand-pc-body-section-1-img-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 19px 20px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container {
    position: relative;
    padding-top: 2683px;
    background-image: url('../../../../../assets/images/temp/pc-section-2-bg.png');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(54px);
    background-color: rgb(0, 0, 0.2);
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(54px);
}
.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-title {
    position: absolute;
    top: 180px;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    color: var(--basewhite);
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-text-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;
    height: 268px;
    position: absolute;
    top: 338px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-square-img-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: absolute;
    top: 706px;
    overflow-x: hidden;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-experience-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: absolute;
    top: 1286px;
    width: 402px;
    height: 107px;
}
.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-experience-text {
    color: var(--primaryp500);
}


.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-wellness-ticket-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: absolute;
    top: 1653px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-wellness-ticket-intro-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;    
    color: var(--basewhite);
}
.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-wellness-ticket-intro-description {
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    color: var(--basewhite);
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-center-card-container {
  display: flex;
  gap: 20px;
  position: absolute;
  top: 1841px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-wellenss-ticket-purchase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: absolute;
    top: 2338px;
}
  
.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-2 .brand-pc-body-section-2-bg-container .brand-pc-body-section-2-overlay .brand-pc-body-section-2-wellenss-ticket-purchase-text {
    color: var(--primaryp500);
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 {
  height: 1422px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container {
  display: flex;
  flex-direction: column;
  gap: 169px;
  width: 1240px;
  margin-block: 151px 128px;
  margin-inline: auto;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container {
  display: flex;
  position: relative;
  gap: 113px;
  padding-right: 105px;
}
.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-left-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 20px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container .brand-pc-body-section-3-left-container .brand-pc-body-section-3-content-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container .brand-pc-body-section-3-left-container .brand-pc-body-section-3-content-container .brand-pc-body-section-3-sub-content {
  font-size: 36px;
  font-weight: 500;
  line-height: 43.2px;
  text-align: left;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container .brand-pc-body-section-3-left-container .brand-pc-body-section-3-content-container .brand-pc-body-section-3-main-content {
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: left;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container .brand-pc-body-section-3-left-container .brand-pc-body-section-3-tour-btn {
  background-color: var(--baseblack);
  color: var(--basewhite);
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container .brand-pc-body-section-3-left-container .brand-pc-body-section-3-tour-btn .detail-btn-pc-icon {
  fill: var(--basewhite);
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-top-container .brand-pc-body-section-3-reservation-intro-img {
  min-width: 449px;
  padding-top: 69.3%;
  background-image: url('https://img.positivehotel.io/2024/12/24/brand/pc/section-3/pc-section-3-intro-img.png');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-inquiry-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-inquiry-container .brand-pc-body-section-3-inquiry-description {
  font-size: 32px;
  font-weight: 500;
  line-height: 41.6px;  
}

.brand-pc-body-wrapper .brand-pc-body-container .brand-pc-body-section-3 .brand-pc-body-section-3-container .brand-pc-body-section-3-inquiry-container .brand-pc-body-section-3-inquiry-kakao-talk {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAE100;
  border-radius: 50%;
  cursor: pointer;
}