.square-img-card-mo-wrapper .square-img-card-mo-container {
    position: relative;
    width: 50vw;
    padding-top: 100%;
}

.square-img-card-mo-wrapper .square-img-card-mo-container .square-img-card-mo-img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
}

.square-img-card-mo-wrapper .square-img-card-mo-container .square-img-card-mo-title{
    position: absolute;
    bottom: 16px;
    left: 16px;
    text-align: left;
    color: var(--basewhite);
}