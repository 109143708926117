
.swiper {
    width: 247px;
    height: 430px;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  .swiper-slide:nth-child(1n) {
    /* background-color: rgb(206, 17, 17); */
    background-color: white;
  }
  
  .swiper-slide:nth-child(2n) {
    background-color: rgb(0, 140, 255);
    /* border: 2px solid #FFFFFF;  */
  }
  
  .swiper-slide:nth-child(3n) {
    /* background-color: rgb(10, 184, 111); */
    background-color: white;
  }
  
  .swiper-3d .swiper-slide-shadow{
    background-color: white;
  }