.scheduleTable table thead tr th {
    text-align: center !important;
    border-right: 0 !important;
    border-left: 0 !important;
}
.scheduleTable table tbody tr .ant-table-cell {
    text-align: center !important;
    border-right: 0 !important;
    border-left: 0 !important;
}
.isScrolled table thead tr > th:first-child {
    box-shadow: 4px 0px 4px 0px #0000001A !important;
}
.isScrolled table tbody tr > td:first-child {
    box-shadow: 4px 0px 4px 0px #0000001A !important;
}