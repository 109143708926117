.brand-mo-footer-wrapper .brand-mo-footer-container {
    background-color: var(--secondarys100);
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-inline: auto;
    padding: 80px 20px;
}

.brand-mo-footer-wrapper .brand-mo-footer-container .brand-mo-footer-title-container {
    display: flex;
    justify-content: space-between;
    color: var(--secondarys800);
}

.brand-mo-footer-wrapper .brand-mo-footer-container .brand-mo-footer-info-container {
    color: var(--secondarys800);
    text-align: left;    
}
