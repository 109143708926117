.scroll-top-btn-wrapper .scroll-top-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: var(--basewhite);
    border-radius: 50px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.scroll-top-btn-wrapper .scroll-top-btn-container .scroll-top-btn-icon {
    cursor: pointer;
    transform: rotate(270deg);
    fill: var(--baseblack);
}