.brand-pc-footer-wrapper {
    height: 494px;
    background-color: var(--secondarys100);
}

.brand-pc-footer-wrapper .brand-pc-footer-container {
    display: flex;
    flex-direction: column;
    gap: 54px;
    width: 1240px;
    margin-inline: auto;
    padding-top: 120px;
}

.brand-pc-footer-wrapper .brand-pc-footer-container .brand-pc-footer-title-container {
    display: flex;
    justify-content: space-between;
}

.brand-pc-footer-wrapper .brand-pc-footer-container .brand-pc-footer-title-container .brand-pc-footer-title-sns-container {
    display: flex;
    gap: 16px;
}

.brand-pc-footer-wrapper .brand-pc-footer-container .brand-pc-footer-title-container .brand-pc-footer-title-sns-container .brand-pc-footer-title-sns-kakao {
    width: 36px;
    height: 36px;
    background-image: url('../../../../../assets/images/icon/gray-kakao-talk.svg');
    cursor: pointer;
}

.brand-pc-footer-wrapper .brand-pc-footer-container .brand-pc-footer-title-container .brand-pc-footer-title-sns-container .brand-pc-footer-title-sns-insta {
    width: 36px;
    height: 36px;
    background-image: url('../../../../../assets/images/icon/gray-insta.svg');
    cursor: pointer;
}

.brand-pc-footer-wrapper .brand-pc-footer-container .brand-pc-footer-info-container {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;    
}