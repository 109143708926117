.square-img-card-pc-wrapper .square-img-card-pc-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.square-img-card-pc-wrapper .square-img-card-pc-container .square-img-card-pc-title{
    position: absolute;
    bottom: 32px;
    left: 40px;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: var(--basewhite);
}