.brand-mo-wrapper {
    background-color: var(--secondarys200);
}

.brand-mo-wrapper .brand-mo-container {
    position: relative;
    max-width: 480px;
    margin-inline: auto;
    background-color: var(--basewhite);
    width: auto;
    min-height: 100vh;
}

.brand-mo-wrapper .brand-mo-container .brand-mo-link-btn-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1;
}

.brand-mo-wrapper .brand-mo-container .brand-mo-link-btn-container .brand-mo-link-event-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 56px;
    height: 56px;
    background-color: var(--primaryp800);
    border-radius: 50%;
    cursor: pointer;
}

.brand-mo-wrapper .brand-mo-container .brand-mo-link-btn-container .brand-mo-link-event-btn-container .brand-mo-link-event-btn-title {
    color: var(--basewhite);
}

.brand-mo-wrapper .brand-mo-container .brand-mo-link-btn-container .brand-mo-link-event-btn-container .brand-mo-link-event-btn-content{
    color: var(--basewhite);
}

.brand-mo-wrapper .brand-mo-container .brand-mo-link-btn-container .brand-mo-link-kakao-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: #FAE100;
    border-radius: 50%;
    cursor: pointer;
}