.brad-center-card-wrapper .brad-center-card-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 400px;
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-img-container{
    position: relative;
    /* border-radius: var(--radius8); */
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-img-container .brad-center-card-img {
    border-radius: var(--radius8);
}



.brad-center-card-wrapper .brad-center-card-container .brad-center-card-img-container .brad-center-card-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:12px;
    color: white;
    opacity: 0;
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-img-container:hover .brad-center-card-img-overlay {
    opacity:1;
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-img-container .brad-center-card-img-overlay.brad-center-card-img-overlay-pointer  {
    cursor: pointer;
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-img-container .brad-center-card-img-overlay .brad-center-card-img-overlay-icon {
    fill: var(--basewhite);
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-content-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-content-container .brad-center-card-title-title {
    color: var(--basewhite);
}

.brad-center-card-wrapper .brad-center-card-container .brad-center-card-content-container .brad-center-card-title-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: var(--basewhite);
    white-space: pre;
}