.brand-pc-header-wrapper {
    background-color: var(--basewhite);
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
}
.brand-pc-header-wrapper .brand-pc-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    height: 42px;
    padding: 32px 0;
    margin: 0 auto;
}

.brand-pc-header-wrapper .brand-pc-header-container .brand-pc-header-logo {
    width: 305px;
    min-width: 305px;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 24px;
    width: 557px;
    height: 42px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .about-btn {
    cursor: pointer;
    margin-block: 2.5px;
    padding: 4px 12px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .about-btn:hover {
    background-color: var(--secondarys100);
    border-radius: var(--radius8);
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .about-btn .text{
    width: 70px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .place-btn {
    cursor: pointer;
    margin-block: 2.5px;
    padding: 4px 12px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .place-btn:hover {
    background-color: var(--secondarys100);
    border-radius: var(--radius8);
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .place-btn .text{
    width: 89px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .program-btn {
    cursor: pointer;
    margin-block: 2.5px;
    padding: 4px 12px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .program-btn:hover {
    background-color: var(--secondarys100);
    border-radius: var(--radius8);
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .program-btn .text{
    width: 83px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .new-member-event-btn {
    cursor: pointer;
    background-color: var(--primaryp800);
    border-radius: var(--radius32);
    padding: 8px 16px;
    width: 139px;
}

.brand-pc-header-wrapper .brand-pc-header-container .nav-container .new-member-event-btn .text{
    color: var(--basewhite);
    width: 139px;
}