.body\/b3 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  text-indent: 0px;
}

.body\/b2 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-indent: 0px;
}

.body\/b1 {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-indent: 0px;
}

.btn\/text-btn {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  text-indent: 0px;
}

.caption\/c1 {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  text-indent: 0px;
}

.display\/d3 {
  font-family: TuskerGrotesk6500Medium;
  font-size: 40px;
  letter-spacing: 0;
  text-indent: 0px;
}

.display\/d2 {
  font-family: TuskerGrotesk6500Medium;
  font-size: 32px;
  letter-spacing: 0;
  text-indent: 0px;
}

.display\/d1 {
  font-family: TuskerGrotesk6500Medium;
  font-size: 24px;
  letter-spacing: 0;
  text-indent: 0px;
}

.sub-title\/st5 {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.sub-title\/st4 {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.sub-title\/st3 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.sub-title\/st2 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-indent: 0px;
}

.sub-title\/st1 {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-indent: 0px;
}

.title\/t4 {
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.title\/t3 {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.title\/t2 {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.title\/t1 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 120%;
  text-indent: 0px;
}

.base\/white {
  color: #ffffff;
}

.base\/black {
  color: #000000;
}

.dim\/d100 {
  color: #00000073;
}

.dim\/d200 {
  color: #000000b3;
}

.dim\/d300 {
  color: #000000cc;
}

.error\/e100 {
  color: #ffcccc;
}

.error\/e200 {
  color: #ffb2b2;
}

.error\/e300 {
  color: #ff9999;
}

.error\/e400 {
  color: #ff6666;
}

.error\/e500 {
  color: #ff3333;
}

.error\/e600 {
  color: #ff0000;
}

.error\/e700 {
  color: #cc0000;
}

.error\/e800 {
  color: #990000;
}

.error\/e900 {
  color: #660000;
}

.primary\/p100 {
  color: #fffdd9;
}

.primary\/p200 {
  color: #fffcb4;
}

.primary\/p300 {
  color: #fffa8e;
}

.primary\/p400 {
  color: #fff969;
}

.primary\/p500 {
  color: #fff743;
}

.primary\/p600 {
  color: #e7df3d;
}

.primary\/p700 {
  color: #d1cb36;
}

.primary\/p800 {
  color: #a39e28;
}

.primary\/p900 {
  color: #76721b;
}

.primary\/p50 {
  color: #fffeec;
}

.secondary\/s100 {
  color: #f4f4f4;
}

.secondary\/s200 {
  color: #e9e9e9;
}

.secondary\/s300 {
  color: #d9d9d9;
}

.secondary\/s400 {
  color: #aeaeae;
}

.secondary\/s500 {
  color: #7d7d7d;
}

.secondary\/s600 {
  color: #737373;
}

.secondary\/s700 {
  color: #676767;
}

.secondary\/s800 {
  color: #555555;
}

.secondary\/s850 {
  color: #363636;
}

:root {
  --font-familiespretendard: Pretendard Variable;
  --font-familiestusker-grotesk: Tusker Grotesk;
  --font-size0: 12px;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 22px;
  --font-size5: 24px;
  --font-size6: 28px;
  --font-size7: 32px;
  --font-size8: 40px;
  --font-weightspretendard-0: Bold;
  --font-weightspretendard-1: SemiBold;
  --font-weightspretendard-2: Regular;
  --font-weightstusker-grotesk-3: 6500 Medium;
  --letter-spacing0: 0%;
  --letter-spacing1: 0;
  --line-heights0: 120%;
  --line-heights1: 24px;
  --line-heights2: 20px;
  --line-heights3: 28px;
  --line-heights4: AUTO;
  --text-indent0: 0px;
  --text-spacing0: 0;
  --radius4: 4px;
  --radius8: 8px;
  --radius16: 16px;
  --radius24: 24px;
  --radius32: 32px;
  --radius40: 40px;
  --radius48: 48px;
  --radius56: 56px;
  --radius64: 64px;
  --space2: 2px;
  --space4: 4px;
  --space8: 8px;
  --space12: 12px;
  --space16: 16px;
  --space20: 20px;
  --space32: 32px;
  --space40: 40px;
  --space48: 48px;
  --space56: 56px;
  --space64: 64px;
  --basewhite: #ffffff;
  --baseblack: #000000;
  --dimd100: #00000073;
  --dimd200: #000000b3;
  --dimd300: #000000cc;
  --errore100: #ffcccc;
  --errore200: #ffb2b2;
  --errore300: #ff9999;
  --errore400: #ff6666;
  --errore500: #ff3333;
  --errore600: #ff0000;
  --errore700: #cc0000;
  --errore800: #990000;
  --errore900: #660000;
  --primaryp100: #fffdd9;
  --primaryp200: #fffcb4;
  --primaryp300: #fffa8e;
  --primaryp400: #fff969;
  --primaryp500: #fff743;
  --primaryp600: #e7df3d;
  --primaryp700: #d1cb36;
  --primaryp800: #a39e28;
  --primaryp900: #76721b;
  --primaryp50: #fffeec;
  --secondarys100: #f4f4f4;
  --secondarys200: #e9e9e9;
  --secondarys300: #d9d9d9;
  --secondarys400: #aeaeae;
  --secondarys500: #7d7d7d;
  --secondarys600: #737373;
  --secondarys700: #676767;
  --secondarys800: #555555;
  --secondarys850: #363636;
}

