.brand-mo-header-wrapper {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    max-width: 480px;
    background-color: var(--basewhite);
}

.brand-mo-header-wrapper .brand-mo-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    height: 24px;
}

.brand-mo-header-wrapper .brand-mo-header-container .brand-mo-header-logo {
    cursor: pointer;
}

.brand-mo-header-wrapper .brand-mo-header-container .brand-mo-header-sns-container {
    display: flex;
    gap: 12px;
}

.brand-mo-header-wrapper .brand-mo-header-container .brand-mo-header-sns-container .brand-mo-header-sns-kakao {
    width: 24px;
    height: 24px;
    background-image: url('../../../../../assets/images/icon/gray-kakao-talk.svg');
    cursor: pointer;
}

.brand-mo-header-wrapper .brand-mo-header-container .brand-mo-header-sns-container .brand-mo-header-sns-insta {
    width: 24px;
    height: 24px;
    background-image: url('../../../../../assets/images/icon/gray-insta.svg');
    cursor: pointer;
}
