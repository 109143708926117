.detail-btn-mo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: var(--basewhite);
    width: 100%;
    height: 43px;
    border-radius: 70px;
    cursor: pointer;
}

.detail-btn-mo-container .detail-btn-mo-icon {
    fill: var(--baseblack);
}

.detail-btn-mo:hover{
    color: var(--basewhite);
    background-color: #d4d4d4;
}

.detail-btn-mo:hover .detail-btn-mo-icon {
    fill: var(--basewhite);
}