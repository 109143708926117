.SolutionFormWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .SolutionFormWrap .cardContainer {
    display: flex;
    justify-content: center;
    width: 90vw;
    max-width: 260px;
    height: 300px;
  }
  
  .SolutionFormWrap .card {
    position: absolute;
    background-color: white;
    width: 100%;
    max-width: 260px;
    height: 300px;
    box-shadow: 0px 18px 53px -5px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
  }
  
  .SolutionFormWrap h3 {
    margin-bottom: 10px;
    color: #fff;
  }