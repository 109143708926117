.brand-text-card-mo-wrapper {
    width: 100%;
}

.brand-text-card-mo-wrapper .brand-text-card-mo-container {
    text-align: center;
}

.brand-text-card-mo-wrapper .brand-text-card-mo-container .brand-text-card-mo-content-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.brand-text-card-mo-wrapper .brand-text-card-mo-container .brand-text-card-mo-content-container .brand-text-card-mo-main-content {
    color: var(--basewhite);
    white-space: pre;
}

.brand-text-card-mo-wrapper .brand-text-card-mo-container .brand-text-card-mo-content-container .brand-text-card-mo-sub-content {
    color: #FFFFFF80;
}

.infinite-scroll-carousel-container {
    display: flex;
    overflow-x: hidden; 
    gap: 24px;
    padding-left: 20px;
    padding-right: 33%;
}